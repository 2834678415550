<template>
  <div class="nav__title nav__title--main">
    <slot></slot>
    <!-- <i
      class="ico--arr-down"
      v-if="this.$parent.withSubNav"
    >
    </i> -->

    <router-link
      :to="path || subnavPath"
      v-if="path || subnavPath"
    >
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NavTitle',

  props: {
    path: ''
  },

  data () {
    return {
      subnavPath: '',
      defaultPath: null,
      routePath: null
    }
  },

  inject: {
    handleNav: {}
  },

  mounted () {
    this.$nextTick(() => {
    })
  }
}
</script>
